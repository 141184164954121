<template>
  <v-card rounded="lg">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ $t('label.time_spent_on') }}:
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ $t('label.time_spent_description') }}:
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item
            v-for="item in items"
            :key="$t(item.title)"
            link
            @click="onSelectItem(item.value)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    items: [
      { title: 'label.technologies', icon: 'mdi-language-php', value: 'technologies' },
      { title: 'label.projects', icon: 'mdi-floor-plan', value: 'projects' },
      { title: 'label.operating_systems', icon: 'mdi-linux', value: 'systems' },
      { title: 'label.ide_browser', icon: 'mdi-code-braces', value: 'editors' },
      { title: 'label.frameworks', icon: 'mdi-laravel', value: 'frameworks' },
    ],
    selectedItem: null
  }),
  methods: {
    /**
     * On select item
     * @param item
     */
    onSelectItem(item) {
      this.selectedItem = item
      this.$emit('selectItem', item);
    }
  }
}
</script>